import { Command } from '../../contracts/command';
import { Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { Nft } from 'src/app/common/entities/nft';
import Web3 from 'web3';
import { User } from 'src/app/common/entities/user';
import { UserDao } from 'src/app/data-access/dao/user-dao.service';
import { StorageService } from 'src/app/data-access/services/storage.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class ApproveMetamask extends Command<boolean> {
  private contract: number;

  constructor(public userDao: UserDao ) {
    super();
    this.contract = 721;
  }

  setParams(...params: any[]) {
    this.contract = <number>params[0];
  }
  
  execute(): Observable<boolean> {
    let isSuccess = false;
    const subject: Subject<boolean> = new Subject();
    this.approve(isSuccess, subject);
    return subject.asObservable();
  }

  async approve(isSuccess: boolean, subject: Subject<boolean>) {
    let user: User =  this.userDao.getUser();
    const operatorWallet = environment.operatorWallet;

    let contractABI = require("src/assets/metamask/contract-abi_721royalty.json");
    let contractAddress = environment.contractAddress721;

    if (this.contract === 1155){
      contractABI = require("src/assets/metamask/contract-abi_1155royalty.json");
      contractAddress = environment.contractAddress1155;
    }

    console.log(this.contract);
    console.log(this.contract === 1155);

    var web3 = new Web3(Web3.givenProvider || 'ws://some.local-or-remote.node:8546');
    const ercContract = new web3.eth.Contract(contractABI, contractAddress);
      const _ = await ercContract.methods.setApprovalForAll(operatorWallet, true).send({from: user.walletToken})
      .on('transactionHash', function(hash: any){
        isSuccess = true;
        subject.next(isSuccess);
      }).on('reject', () => console.log('eee'));
  }
}