import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { TranslateService } from '@ngx-translate/core';
import { LanguageDao } from './data-access/dao/languaje-dao.service';

declare let gtag: Function;
declare let fbq: Function;

@Component( {
  selector: 'app-my-app',
  templateUrl: './app.component.html'
} )

export class AppComponent implements OnInit {
  private _router: Subscription;

  constructor(
    private router: Router,
    private translate: TranslateService,
    private languageDao: LanguageDao) {
    const lang = languageDao.getLanguage();
    translate.setDefaultLang( lang ?? 'en' );
  }

  ngOnInit() {

    this._router = this.router.events.filter( event => event instanceof NavigationEnd ).subscribe( ( event: NavigationEnd ) => {
      const body = document.getElementsByTagName( 'body' )[ 0 ];
      const modalBackdrop = document.getElementsByClassName( 'modal-backdrop' )[ 0 ];
      if ( body.classList.contains( 'modal-open' ) ) {
        body.classList.remove( 'modal-open' );
        modalBackdrop.remove();
      }

      gtag( 'config', 'UA-131185885-2',
        {
          'page_path': event.urlAfterRedirects
        } );

      fbq( 'track', 'PageView' );
    } );
  }

}
