import { BaseEntity } from "./base-entity";

export class User extends BaseEntity {
  public name: string;
  public lastName: string;
  public email: string;
  public username: string;
  public password: string;
  public role: string;
  public profileImage: string;
  public newPassword: string;
  public registeredAt: string;
  public isSuspended: boolean;
  public biography: string;
  public website: string;
  public instagram: string;
  public walletToken: string;
  public aproveForAll: boolean
}