import { Injectable } from '@angular/core';
import { StorageService } from '../services/storage.service';
import { environment } from 'src/environments/environment';

/**
 * A service for the auth dao
 * @class
 */
@Injectable()
export class AuthDao {
  constructor (private storageService: StorageService) {}

  saveAuth(auth: string): void {
    this.storageService.set(environment.authKey, auth, true);
  }

  getAuth(): string {
    let result = null;
    const stringPerson = this.storageService.get(environment.authKey);

    if (stringPerson) {
      result = stringPerson;
    }

    return result;
  }

  deleteAuth(): void {
    this.storageService.delete(environment.authKey, true);
  }

}
