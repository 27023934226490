// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  apiUrl: 'https://mint.artatak.io:8443/artatak',
  storeUrl: 'https://artatak.io/#/',
  authKey: 'auth',
  userKey: 'user',
  languageKey: 'language',
  lastAccessKey: 'lastaccess',
  contractAddress721: '0x737C33b16F25C0f10c56d56B59f2834c3795395F',
  operatorWallet: '0x7eD555D5E2c1f15dF319613E851d5390878879Dc',
  contractAddress1155: '0xd283C0A05698A43fFBCBbE24be1f65A19dea1324'
};
