import { Injectable } from '@angular/core';
import { StorageService } from '../services/storage.service';
import { environment } from 'src/environments/environment';

/**
 * A service for the language dao
 * @class
 */
@Injectable()
export class LanguageDao {
  constructor (private storageService: StorageService) {
      
  }

  saveLanguage(language: string): void {
    this.storageService.set(environment.languageKey, language, true);
  }

  getLanguage(): any {
    const res = this.storageService.get(environment.languageKey);
    return res ?? 'en';
  }

  deleteLanguage(): void {
    this.storageService.delete(environment.languageKey, true);
  }
}