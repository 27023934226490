import { Command } from '../../contracts/command';
import { Injectable } from '@angular/core';
import { User } from 'src/app/common/entities/user';
import { first } from 'rxjs/operators';
import { NetworkChainType } from 'src/app/common/entities/network-chain-type';
import { MessageService } from 'src/app/common/services/message.service';
import { UserDao } from 'src/app/data-access/dao/user-dao.service';
import { EditProfile } from '../user/edit-profile';
import { Observable, Subject } from 'rxjs';
declare const window: any;

@Injectable()
export class LoginMetamask extends Command<User> {
    constructor(private messageService: MessageService,
                private userDao: UserDao,
                private editProfileCommand: EditProfile) {
        super();
    }

    setParams(...params: any[]) {
    }
    
    execute(): Observable<boolean> {
        const subject: Subject<any> = new Subject();
        try {
            window.ethereum.request({ method: 'eth_requestAccounts' }).then( async accounts => {
                const user: User =  this.userDao.getUser();
                const response = await this.editAccount(accounts[0], user);
                subject.next(response);
            });
        } catch(error){
            this.dispatchErrorMessage();
            subject.next(false);
        }
        return subject.asObservable();
    }

  async editProfile(user: User): Promise<boolean> {
    try {
      this.editProfileCommand.setParams(user);
      const isSuccess = await this.editProfileCommand.execute().pipe(first()).toPromise();
      if (isSuccess) {
        this.messageService.addMessage('', 'Connection established with metamask.', false, false);
        this.messageService.showMessageAsToast();
        return true;
      } else {
        this.dispatchErrorMessage();
        return false;
      }
    } catch (error) {
      this.dispatchErrorMessage();
      return false;
    }
  }

  async editAccount(account: string, user: User) {
    if (!account) {
      throw new Error();
    }
    const network: string = await window.ethereum.networkVersion;
    if (network) {
      user.walletToken = account;
      await this.editProfile(user);
      return {isLoggedIn: account != null};
    } else {
      this.messageService.addMessage('', 'The network you are connected to is not valid for minting please change network to Polygon', true, false);
      this.messageService.showMessageAsToast();
    }
  }

  dispatchErrorMessage() {
    this.messageService.addMessage('', 'Connection not established with metamask, please download Metamask for you browser and login.', true, false);
      this.messageService.showMessageAsToast();
      setTimeout(function() {
        window.open('https://metamask.io/download/','_blank');
      }, 2000);
  }
}