import { Observable } from "rxjs";

export abstract class Command<T> {
  protected result: T;
  protected error: any;

  abstract setParams(...params: any[]);
  abstract execute(): Observable<any|boolean>;
  
  getResult(): T {
    return this.result;
  }

  getError(): string {
    return this.error && this.error && this.error.includes('|')
      ? this.error.split('|')[1]
      : this.error;
  }

  getCode(): string {
    return this.error && this.error && this.error.includes('|')
      ? this.error.split('|')[0]
      : this.error;
  }
}