import { Injectable } from '@angular/core';
import { StorageService } from '../services/storage.service';
import { environment } from 'src/environments/environment';

/**
 * A service for the auth dao
 * @class
 */
@Injectable()
export class UserDao {
  constructor (private storageService: StorageService) {}

  saveUser(user: any): void {
    this.storageService.set(environment.userKey, JSON.stringify(user), true);
  }

  getUser(): any {
    let result = null;
    const stringPerson = this.storageService.get(environment.userKey);

    if (stringPerson) {
      result = <any>JSON.parse(stringPerson);
    }

    return result;
  }

  isAdmin() {
    return this.getUser() && this.getUser().role == 'Admin'
  }

  isGallery() {
    return this.getUser() && this.getUser().role == 'Gallery'
  }

  isArtist() {
    return this.getUser() && this.getUser().role == 'Artist'
  }

  deleteUser(): void {
    this.storageService.delete(environment.userKey, true);
  }

}
