import { Injectable } from '@angular/core';

declare const $: any;

/**
 * A service to set the global messages
 * @class
 */
@Injectable()
export class MessageService {
  private _title: string;
  private _message: string;
  private _isError: boolean;
  public _showMessage: boolean;

  constructor ( ) {}

  /**
   * Method that return if a message is an error
   */
  get isError () : boolean {
    return this._isError;
  }

  /**
   * Method that return the message
   */
  get message () : string {
    return this._message;
  }

  /**
   * Method that return the title
   */
  get title () : string {
    return this._title;
  }

  /**
   * Method that return if the message is visible
   */
  get isVisible () : boolean {
    return this._showMessage;
  }
  
  /**
   * Method to set the message
   * @param title The title of the message
   * @param message The message
   * @param isError True if the message is an error
   * @param showMessage True if the message will be displayed
   */
  addMessage ( title: string, message: string, isError: boolean, showMessage?: boolean ) : void {
    this._message = message;
    this._title = title;
    this._isError = isError;
    this._showMessage = showMessage;
  }

  resetMessage() : void {
    this._message = '';
    this._title = '';
    this._isError = false;
  }

  hideMessage() : void {
    this._showMessage = false;
  }
  
  /**
   * Method to show the message as toast
   */
  showMessageAsToast () : void {
    const template = '<div data-notify="container" class="col-xs-11 col-sm-3 alert alert-{0} right-padding" role="alert">' +
      '<button mat-raised-button type="button" aria-hidden="true" class="close" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
      '<i class="material-icons" data-notify="icon">notifications</i> ' +
      '<span data-notify="title">{1}</span> ' +
      '<span data-notify="message">{2}</span>' +
      '<div class="progress" data-notify="progressbar">' +
        '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
      '</div>' +
      '<a href="{3}" target="{4}" data-notify="url"></a>' +
    '</div>';

    $.notify({
      icon: 'notifications',
      message: this.message
    },{
      type: this._isError ? 'danger' : 'success',
      timer: 3000,
      z_index: 2000,
      placement: {
          from: 'bottom',
          align: 'right'
      },
      template: template
    });
  }
}