import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

/**
 * A service to make http requests (GET, POST, PUT and DELETE) to the transactional server
 * @class
 */
@Injectable()
export class HttpService {
  _headers: HttpHeaders;
  _baseUrl: string;

  constructor( private _http: HttpClient ) {
    this._headers = new HttpHeaders();
    this._headers = this._headers.set('Content-Type', 'application/json');
    this._baseUrl = environment.apiUrl;
  }

  /**
   * Function to make a GET request
   *
   * @param {string} path Path of the get request in the transactional server
   * @param {Array< any >} keys The query strings in format { name: number|string, value: number|string }
   * @param {Headers} optionalHeaders The optional headers in the request
   */
  get( path: string, keys: Array< any >, optionalHeaders: HttpHeaders, fullResponse: boolean = false ): Observable< any > {
    let headers: HttpHeaders  = this._headers;
    let fullPath: string = `${this._baseUrl}${path}`;
    let keysString: string = this.buildQueryStrings( keys );
    fullPath += keysString;

    if ( optionalHeaders ) {
      headers.keys().forEach( function ( name ) {
        optionalHeaders = optionalHeaders.append( name, headers.get(name) );
      });
    }

    const selectedHeaders = ( optionalHeaders != null ) ? optionalHeaders : headers;
    const options = { headers : selectedHeaders };
    if ( fullResponse ) {
      options['observe'] = 'response';

      return this._http.get( fullPath, options );
    }

    return this._http.get( fullPath, options ).pipe(
      map( res => JSON.parse(JSON.stringify(res)) )
    );
  }

  /**
   * Function to make a GET request to a different API
   * @param path server url
   */
  getWithPath( path: string ) {

    return this._http.get( path )
      .pipe( map( res => JSON.parse( JSON.stringify( res ) ) ) );
  }

  /**
   * Function to make a POST request
   *
   * @param {string} path Path of the post request in the transactional server
   * @param {any} object Object to post
   * @param {Array< any >} keys The query strings in format { name: number|string, value: number|string }
   */
  post( path: string, object: any, keys: Array< any >, optionalHeaders: HttpHeaders, observe: boolean = false ): Observable< any > {
    let headers: HttpHeaders  = this._headers;
    let fullPath: string = `${this._baseUrl}${path}`;
    let keysString: string = this.buildQueryStrings( keys );
    fullPath += keysString;

    if ( optionalHeaders ) {
      headers.keys().forEach( function ( name ) {
        optionalHeaders = optionalHeaders.append( name, headers.get(name) );
      });
    }

    const selectedHeaders = ( optionalHeaders != null ) ? optionalHeaders : headers;
    const options = { headers : selectedHeaders };
    if (observe) {
      options['reportProgress'] = true;
      options['observe'] = 'events';
    }

    return this._http.post( fullPath, object, options ).pipe(
      map( res => { return JSON.parse(JSON.stringify(res)); } )
    );
  }

  /**
   * Function to make a POST request to a different API
   * @param path server url
   * @param object body of the request
   */
  postWithPath( path: string, object: any ) {

    return this._http.post( path, object )
      .pipe( map( res => JSON.parse( JSON.stringify( res ) ) ) );
  }

  /**
   * Function to make a PUT request
   *
   * @param {string} path Path of the put request in the transactional server
   * @param {any} object Object to put
   * @param {Array< any >} keys The query strings in format { name: number|string, value: number|string }
   */
  put( path: string, object: any, keys: Array< any >, optionalHeaders: HttpHeaders, observe: boolean = false ): Observable< any > {
    let headers: HttpHeaders  = this._headers;
    let fullPath: string = `${this._baseUrl}${path}`;
    let keysString: string = this.buildQueryStrings( keys );
    fullPath += keysString;

    if ( optionalHeaders ) {
      headers.keys().forEach( function ( name ) {
        optionalHeaders = optionalHeaders.append( name, headers.get(name) );
      });
    }

    const selectedHeaders = ( optionalHeaders != null ) ? optionalHeaders : headers;
    const options = { headers : selectedHeaders };
    if (observe) {
      options['reportProgress'] = true;
      options['observe'] = 'events';
    }
    return this._http.put( fullPath, object, options ).pipe( 
      map( res => { return JSON.parse(JSON.stringify(res)); } ) 
    );
  }

  /**
   * Function to make a PUT request to a different API
   * @param path server url
   * @param object body of the request
   */
  putWithPath( path: string, object: any ) {

    return this._http.put( path, object )
      .pipe( map( res => JSON.parse( JSON.stringify( res ) ) ) );
  }

  /**
   * Function to make a DELETE request
   *
   * @param {string} path Path of the delete request in the transactional server
   * @param {Array< any >} keys The query strings in format { name: number|string, value: number|string }
   */
  delete( path: string, keys: Array< any >, optionalHeaders: HttpHeaders ): Observable< any > {
    let headers: HttpHeaders  = this._headers;
    let fullPath: string = `${this._baseUrl}${path}`;
    let keysString: string = this.buildQueryStrings( keys );
    fullPath += keysString;

    if ( optionalHeaders ) {
      headers.keys().forEach( function ( name ) {
        optionalHeaders = optionalHeaders.append( name, headers.get(name) );
      });
    }

    const selectedHeaders = ( optionalHeaders != null ) ? optionalHeaders : headers;

    return this._http.delete( fullPath, { headers : selectedHeaders } ).pipe(
      map( res => { return JSON.parse(JSON.stringify(res)); } )
    );
  }

  /**
   * Function to construct the string of query strings for a request
   *
   * @param {Array< any >} keys The query strings in format { name: number|string, value: number|string }
   */
  private buildQueryStrings ( keys: Array< any > ) {
    let keysString: string = '';

    for ( let index = 0; index < keys.length; index++ ) {
      const element = keys[ index ];

      let tempString = `${element.name}=${element.value}`;
      keysString = ( keysString === '' ) ? `?${tempString}` : `${keysString}&${tempString}`;
    }

    return keysString;
  }
}
