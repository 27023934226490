import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { LanguageDao } from 'src/app/data-access/dao/languaje-dao.service';
import { AuthDao } from 'src/app/data-access/dao/auth-dao.service';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {

  constructor(
    private authDao: AuthDao,
    private languageDao: LanguageDao,
    private router: Router ) { }


  intercept( req: HttpRequest<any>, next: HttpHandler ): Observable<HttpEvent<any>> {
    const lang = this.languageDao.getLanguage() ?? 'en';
    const token = this.authDao.getAuth();
    
    let request = req;

    if ( token ) {
      request = req.clone( {
        setHeaders: {
          authorization: token,
          language: lang
        }
      } );
    } else {
      request = req.clone( {
        setHeaders: {
          language: lang
        }
      } );
    }

    return next.handle( request ).pipe(
      catchError( ( err: HttpErrorResponse ) => {

        if ( err.status === 401 ) {
          this.router.navigate(['au', 'login']);
        }

        return throwError( err );

      } )
    );

  }


}
