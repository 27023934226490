import { Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpService } from '../services/http.service';
import { BaseStore, StoreResponse } from './base-store';
import { User } from 'src/app/common/entities/user';

/**
 * The access to users endpoint
 * @class
 */
@Injectable()
export class SessionStore extends BaseStore {
  private path: string = "/sessions";
  
  constructor(private httpService: HttpService) {
    super();
  }

  makeLoginRequest(user: User): Observable<StoreResponse<User>> {
    let subject: Subject< StoreResponse<User> > = new Subject();

    this.httpService.post( this.path, user, [], null ).subscribe(
      ( data ) => {
        subject.next(data);
      },
      ( error ) => {
        subject.error( error.error );
      }
    );

    return subject.asObservable();
  }

  makeForgotPasswordRequest(user: User): Observable<StoreResponse<User>> {
    let subject: Subject< StoreResponse<User> > = new Subject();
    let action = `/password`;

    this.httpService.put( `${this.path}${action}`, user, [], null ).subscribe(
      ( data ) => {
        subject.next(data);
      },
      ( error ) => {
        subject.error( error.error );
      }
    );

    return subject.asObservable();
  }
}