import { Routes } from '@angular/router';

import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { AuthGuard } from './common/guards/auth-guard.service';

export const AppRoutes: Routes = [{
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
}, {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [{
        path: '',
        loadChildren: './admin-pages/dashboard/dashboard.module#DashboardModule'
    },{
        path: '',
        loadChildren: './admin-pages/artists/artists.module#ArtistsModule'
    },{
        path: '',
        loadChildren: './admin-pages/nft-art/nft-art.module#NftArtModule'
    },{
        path: '',
        loadChildren: './admin-pages/my-profile/my-profile.module#MyProfileModule'
    },{
        path: '',
        loadChildren: './admin-pages/admins/admins.module#AdminsModule'
    },
    {
        path: '',
        loadChildren: './admin-pages/events/events.module#EventsModule'
    },
    {
        path: '',
        loadChildren: './admin-pages/collections/collections.module#CollectionsModule'
    },
    {
        path: '',
        loadChildren: './admin-pages/create-nft/create-nft.module#CreateNftModule'
    },
    {
        path: '',
        loadChildren: './admin-pages/sales/sales.module#SalesModule'
    },
    {
        path: '',
        loadChildren: './admin-pages/bids/bids.module#BidsModule'
    }
    ]
}, {
    path: '',
    component: AuthLayoutComponent,
    children: [{
        path: 'au',
        loadChildren: './pages/pages.module#PagesModule'
    }]
}];
