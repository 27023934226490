import { Command } from '../../contracts/command';
import { Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { User } from 'src/app/common/entities/user';
import { UserStore } from 'src/app/data-access/stores/user-store';
import { UserDao } from 'src/app/data-access/dao/user-dao.service';

@Injectable()
export class EditProfile extends Command<User> {
  private user: User;

  constructor(private userStore: UserStore, private userDao: UserDao) {
    super();
  }

  setParams(...params: any[]) {
    this.user = <User>params[0];
  }
  
  execute(): Observable<boolean> {
    let isSuccess = false;
    const subject: Subject<boolean> = new Subject();

    this.userStore.makeEditRequest(this.user).subscribe(
      response => {
        this.userDao.deleteUser();
        this.userDao.saveUser(response.data);

        this.result = response.data;
        isSuccess = true;
        subject.next(isSuccess);
      },
      errorResponse => {
        this.error = errorResponse.error;
        subject.next(isSuccess);
      }
    );
    
    return subject.asObservable();
  }
}