import { HttpHeaders } from '@angular/common/http';
import { AuthDao } from '../dao/auth-dao.service';

export class BaseStore {
  getTokenHeader(authDao: AuthDao): HttpHeaders {
    const token = authDao.getAuth();
    let headers = new HttpHeaders();
    headers = headers.set('token', token);

    return headers;
  }
}

export interface StoreResponse<T> {
  data: T;
  error: string;
  token: string;
}