import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, Route } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthDao } from 'src/app/data-access/dao/auth-dao.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private authDao: AuthDao) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> |
    boolean {
    if (this.authDao.getAuth()) {
      return true;
    }

    this.router.navigate(['au', 'login'], {
      queryParams: {
        return: state.url
      }
    });
    return false;

  }

}
