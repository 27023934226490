import { Component, OnInit } from '@angular/core';
import PerfectScrollbar from 'perfect-scrollbar';
import { AuthDao } from 'src/app/data-access/dao/auth-dao.service';
import { Router } from '@angular/router';
import { User } from 'src/app/common/entities/user';
import { UserDao } from 'src/app/data-access/dao/user-dao.service';
import { LoginMetamask } from 'src/app/logic/commands/impl/metamask/login-metamask';
import { EditProfile } from 'src/app/logic/commands/impl/user/edit-profile';
import { ApproveMetamask } from 'src/app/logic/commands/impl/metamask/approve-metamask';
declare const window: any;
declare const $: any;

//Metadata
export interface RouteInfo {
    path: string;
    title: string;
    type: string;
    icontype: string;
    collapse?: string;
    children?: ChildrenItems[];
}

export interface ChildrenItems {
    path: string;
    title: string;
    ab: string;
    type?: string;
}

//Menu Items
export const ROUTES: RouteInfo[] = [
  {
    path: '/dashboard',
    title: 'Dashboard',
    type: 'link',
    icontype: 'dashboard'
  },
  {
    path: '/nft-art',
    title: 'NFT Art',
    type: 'link',
    icontype: 'code'
  },
  {
    path: '/collections',
    title: 'Collections',
    type: 'link',
    icontype: 'collections'
  },
  {
    path: '/events',
    title: 'Events',
    type: 'link',
    icontype: 'event'
  },
  {
    path: '',
    title: 'Users',
    type: 'sub',
    collapse: 'usersCollapse',
    icontype: 'persons',
    children:[{
      path: '/artists',
      title: 'Artists and Galleries',
      type: 'link',
      ab:''
    },
    {
      path: '/admins',
      title: 'Admins',
      type: 'link',
      ab:''
    }]
  },
  {
    path: '/sales',
    title: 'Sales',
    type: 'link',
    icontype: 'paid'
  },
];
@Component({
  selector: 'app-sidebar-cmp',
  templateUrl: 'sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  providers:[ { provide: LoginMetamask }, { provide: EditProfile },
              { provide: ApproveMetamask }]
              
})

export class SidebarComponent implements OnInit {
    public user: User = new User();
    public menuItems: any[];
    public linkItems: any[];
    ps: any;

    constructor(  private userDao: UserDao, 
                  private authDao: AuthDao, 
                  private router: Router,
                  private loginMetamaskCommand: LoginMetamask,
                  private approveMetamaskCommand: ApproveMetamask) { 
      this.user = <User>this.userDao.getUser();
    }

    isMobileMenu() {
        if ($(window).width() > 991) {
            return false;
        }
        return true;
    };

    ngOnInit() {
        this.linkItems = [
            { name: 'sidebar.my-profile', link: '/myprofile' }
        ];
        this.menuItems = ROUTES.filter(menuItem => menuItem);
        if (!this.userDao.isAdmin()) {
          this.menuItems = this.menuItems.filter(menuItems => menuItems.title != 'Users' && menuItems.title != 'Events' && menuItems.title != 'Sales')
        }
        if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
            const elemSidebar = <HTMLElement>document.querySelector('.sidebar .sidebar-wrapper');
            this.ps = new PerfectScrollbar(elemSidebar);
        }
    }

    updatePS(): void  {
        if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
            this.ps.update();
        }
    }

    isMac(): boolean {
        let bool = false;
        if (navigator.platform.toUpperCase().indexOf('MAC') >= 0 || navigator.platform.toUpperCase().indexOf('IPAD') >= 0) {
            bool = true;
        }
        return bool;
    }

    logout() {
      this.userDao.deleteUser();
      this.authDao.deleteAuth();
      this.router.navigate(['au', 'login']);
    }

    get profileImage() {
        const a = this.userDao.getUser();
        this.user = <User>a;

        return this.user ? this.user.profileImage : '';
    }

    defaultProfileImage( event ) {
      let asset = '';
      
      asset = './assets/img/default.png';

      event.target.src = asset;
    }

    goToDiscover() {
      this.router.navigate(['dashboard']);
    }

    gotLoginMetamask(contract: number) {
      this.loginMetamaskCommand.execute().subscribe(isLoggedIn =>{
          if(isLoggedIn){
            console.log(contract);
            this.approveMetamaskCommand.setParams(contract);
            this.approveMetamaskCommand.execute().subscribe(isApproved =>{
              console.log(isApproved)
              if(isApproved){
                
              }else{
    
              }
            });
          }
        }
      );
    }

    validateLoginMetamask(): boolean {
      return !this.user?.walletToken || !window.ethereum.selectedAddress;
    }
}
