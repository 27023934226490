import { Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpService } from '../services/http.service';
import { BaseStore, StoreResponse } from './base-store';
import { User } from 'src/app/common/entities/user';
import { HttpHeaders, HttpParams } from '@angular/common/http';

/**
 * The access to users endpoint
 * @class
 */
@Injectable()
export class UserStore extends BaseStore {
  private path: string = "/users";
  
  constructor(private httpService: HttpService) {
    super();
  }

  makeRegisterRequest(user: User): Observable<StoreResponse<User>> {
    let subject: Subject< StoreResponse<User> > = new Subject();

    this.httpService.post( this.path, user, [], null ).subscribe(
      ( data ) => {
        subject.next(data);
      },
      ( error ) => {
        subject.error( error.error );
      }
    );

    return subject.asObservable();
  }

  makeRegisterAdminsRequest(user: User): Observable<StoreResponse<User>> {
    let subject: Subject< StoreResponse<User> > = new Subject();

    this.httpService.post( `${this.path}/admins`, user, [], null ).subscribe(
      ( data ) => {
        subject.next(data);
      },
      ( error ) => {
        subject.error( error.error );
      }
    );

    return subject.asObservable();
  }

  makeGetRequest(id: number): Observable<StoreResponse<User>> {
    let subject: Subject< StoreResponse<User> > = new Subject();
    let action = `/${id}`;

    this.httpService.get( `${this.path}${action}`, [], null ).subscribe(
      ( data ) => {
        subject.next(data);
      },
      ( error ) => {
        subject.error( error.error );
      }
    );

    return subject.asObservable();
  }

  makeEditRequest(user: User): Observable<StoreResponse<User>> {
    let subject: Subject< StoreResponse<User> > = new Subject();

    this.httpService.put( this.path, user, [], null ).subscribe(
      ( data ) => {
        subject.next(data);
      },
      ( error ) => {
        subject.error( error.error );
      }
    );

    return subject.asObservable();
  }

  makeChangePasswordRequest(user: User): Observable<StoreResponse<User>> {
    let subject: Subject< StoreResponse<User> > = new Subject();
    const route = '/password';

    this.httpService.put( `${this.path}${route}`, user, [], null ).subscribe(
      ( data ) => {
        subject.next(data);
      },
      ( error ) => {
        subject.error( error.error );
      }
    );

    return subject.asObservable();
  }

  makeGetUsersRequest(page: number, limit: number): Observable<StoreResponse<User[]>> {
    let subject: Subject< StoreResponse<User[]> > = new Subject();
    const query = [
      {
        name: 'page',
        value: page
      }, {
        name: 'limit',
        value: limit
      }
    ];
    this.httpService.get( `${this.path}`, [], null ).subscribe(
      ( data ) => {
        subject.next(data);
      },
      ( error ) => {
        subject.error( error.error );
      }
    );

    return subject.asObservable();
  }

  makeGetAdminsRequest(page: number, limit: number): Observable<StoreResponse<User[]>> {
    let subject: Subject< StoreResponse<User[]> > = new Subject();
    const query = [
      {
        name: 'admins',
        value: true
      }
    ];
    this.httpService.get( `${this.path}`, query, null ).subscribe(
      ( data ) => {
        subject.next(data);
      },
      ( error ) => {
        subject.error( error.error );
      }
    );

    return subject.asObservable();
  }

  makeSuspendReactivateRequest(id: number, suspended: boolean): Observable<StoreResponse<User>> {
    let subject: Subject< StoreResponse<User> > = new Subject();
    const route = `/${id}/issuspended/${suspended}`;
    this.httpService.put( `${this.path}${route}`, null, [], null ).subscribe(
      ( data ) => {
        subject.next(data);
      },
      ( error ) => {
        subject.error( error.error );
      }
    );

    return subject.asObservable();
  }

  makeGetArtistRequest(): Observable<StoreResponse<User[]>> {
    let subject: Subject< StoreResponse<User[]> > = new Subject();
    const query = [
      {
        name: 'artists',
        value: true
      },
    ];
    this.httpService.get( `${this.path}`, query, null ).subscribe(
      ( data ) => {
        subject.next(data);
      },
      ( error ) => {
        subject.error( error.error );
      }
    );

    return subject.asObservable();
  }

  makeGetGalleriesRequest(): Observable<StoreResponse<User[]>> {
    let subject: Subject< StoreResponse<User[]> > = new Subject();
    const query = [
      {
        name: 'galleries',
        value: true
      },
    ];
    this.httpService.get( `${this.path}`, query, null ).subscribe(
      ( data ) => {
        subject.next(data);
      },
      ( error ) => {
        subject.error( error.error );
      }
    );

    return subject.asObservable();
  }

  makeGetArtistsAndGalleriesRequest(): Observable<StoreResponse<User[]>> {
    let subject: Subject< StoreResponse<User[]> > = new Subject();
    this.httpService.get( `${this.path}`, [], null ).subscribe(
      ( data ) => {
        subject.next(data);
      },
      ( error ) => {
        subject.error( error.error );
      }
    );

    return subject.asObservable();
  }

  makeGetAllRequest(): Observable<StoreResponse<User[]>> {
    let subject: Subject< StoreResponse<User[]> > = new Subject();
    const query = [
      {
        name: 'all',
        value: true
      },
    ];
    this.httpService.get( `${this.path}`, query, null ).subscribe(
      ( data ) => {
        subject.next(data);
      },
      ( error ) => {
        subject.error( error.error );
      }
    );

    return subject.asObservable();
  }
}