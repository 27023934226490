import { Injectable } from "@angular/core";
import { NgbDateParserFormatter, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";

@Injectable()
 export class NgbDateParseCustom extends NgbDateParserFormatter {
    parse(date: string): NgbDateStruct {
        return (date && Number(date.substring(0, 4)) && Number(date.substring(5, 7) + 1) && Number(date.substring(8, 10))) ?
        {year: Number(date.substring(0, 4)),
            month: Number(date.substring(5, 7)),
            day: Number(date.substring(8, 10))} : null;
    }
    
    format(date: NgbDateStruct): string {
        if (date){
            return `${this.pad(date?.year)}-${this.pad(date?.month)}-${this.pad(date?.day)}` ;
        }
        return '';
    }
      
    private pad(i: number): string {
        return i < 10 ? `0${i}` : `${i}`;
    }
}