import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class TokenInterceptorService implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
        map(event => {
            let result = event;
            if (event instanceof HttpResponse && (req.url.includes('sessions') || req.url.includes('users'))) {
                const e = event as HttpResponse<any>;
                const token = e.headers.get('token');

                if (token) {
                  result = e.clone({ body: { ...e.body, token } })
                }
            }
            return result;
        })
    );
  }
}